import { SectionForm, TagSelect } from 'components/ui'
import React, { useContext } from 'react'
import { BaseInput } from '../tags/BaseInput'
import { keys } from 'utils/constants/presets.const'
import { AppState } from 'index'
import useRithmSectionSettings from 'hooks/useRithmSectionSettings'
import { TagNumberInput } from '../tags/TagNumberInput'
import BassGenerationButton from './BassGenerationButton'
import { BassResultButton } from './BassResultButton'

export function BassSettingsForm() {
    const state = useContext(AppState)
    const {
        handleChangeKey,
        handleChangeBpm,
        handleChangeBassPreset
    } = useRithmSectionSettings()

  return (
    <SectionForm
        header='Генерация баса'
    >
        <BaseInput name='Preset' idDisabled={false}>
            <TagSelect
                tagList={state.bass_presets.value}
                handleChangeTag={handleChangeBassPreset}
                selectedTag={state.selectedTagSetSettings.value.preset}
                nameForm='Preset'
            />
        </BaseInput>
        <BaseInput name='Тональность' idDisabled={true}>
            <TagSelect 
                tagList={keys}
                handleChangeTag={handleChangeKey}
                selectedTag={keys.find((tag) => tag.name === state.selectedTagSetSettings.value.key)?.id || 0}
                nameForm="Тональность"
            />
        </BaseInput>

        <BaseInput name='Темп' idDisabled={false}>
            <TagNumberInput selectedNumber={state.selectedTagSetSettings.value.bpm} handleChangeNumber={handleChangeBpm} name='bpm'/>
        </BaseInput>

        <BassGenerationButton />

        <BassResultButton />
    </SectionForm>
  )
}
