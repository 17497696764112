import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { AppState } from 'index';
// import { useSignals } from '@preact/signals-react/runtime';
import { BasicAlert } from 'components/forms';
import { AlertSeverity } from 'utils/types/enums/alertSeverity';


export  function Login() {
    // useSignals();
    const state = useContext(AppState);

    const {auth, setAuth} = useAuth();

    const [showPassword, setShowPassword] = React.useState(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [authError, setAuthError] = useState<boolean | null>(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const onLogin = async () => {
        try {
            const response: AxiosResponse<string> = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/login`, {
                username: username,
                password: password,
            });

            console.log(response.data);
            if (response.data === "success"){
                setAuth({username: username, password: password});
                state.isLogin.value = true;
            }
        } catch (error) { 
            state.isLogin.value = false;
            setAuthError(true);
        }
    }

    const getAlert = (errorStatus: boolean | null) => {
        if (errorStatus === true) {
            return <BasicAlert text='Неверное имя пользователя или пароль' severity={AlertSeverity.error} />
        }
        if (errorStatus === false || errorStatus === null) {
            return <></>            
        }
    }


    return (
        <Box component="form" sx={{ mx: 'auto', mt: '160px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Card sx={{ width: '300px', p: '16px', borderRadius: '16px', border: 'none' }} variant="outlined">
                <CardContent>
                    <Typography component="div" variant="h6">Login</Typography>
                    
                    <TextField 
                        value={username}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(event.target.value);
                        }}
                        label='Имя пользователя' 
                        variant='standard'
                        fullWidth
                        />
                    
                    <FormControl sx={{ my: 1, width: '100%' }} variant="standard">

                        
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </CardContent>

                {getAlert(authError)}

                <CardActions>
                    <Button onClick={onLogin} variant='contained'>Войти</Button>
                </CardActions>
            </Card>
        </Box>
    )
}
