import { MenuItem, Paper, Select, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { SectionForm } from '../../ui/form/SectionForm'
import { ButtonGenerate } from 'components/buttons/ButtonGenerate'
import { AppState } from 'index';
import { DownloadArchiveButton } from 'components/buttons/DownloadArchiveButton';
import { BaseInput } from '../tags/BaseInput';
import useSynthSettings from 'hooks/useSynthSettings';
// import useSynthSettings from 'hooks/useSynthSettings';

export function SynthSection() {

    const state = useContext(AppState);
    const {handleChangeSynthPreset} = useSynthSettings();
    
  return (
    <SectionForm
        header='Генерация секции синтезаторов'
    >

        <BaseInput name='Preset' idDisabled={false}>

            <Select 
                value={state.selectedTagSynthSettings.value.preset.toString() || ''}
                onChange={handleChangeSynthPreset}
            >
                <MenuItem key={0} value={0}>Не выбран</MenuItem>
                {
                    state.synth_presets.value.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                    ))
                }
            </Select>
        </BaseInput>

        <ButtonGenerate  variant='synth'/>

        {
            state.generatedSynthPath.value.result_id !== '' ? (
                <DownloadArchiveButton variant='synth' format='midi'/>
            ) : null
        }
    </SectionForm>
  )
}
