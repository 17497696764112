import { FormControl, Stack, Typography } from '@mui/material';
import React from 'react'

type BaseInputProps = {
    children?: React.ReactNode;
    name?: string;
    idDisabled: boolean;
}
export function BaseInput(
    {children, name, idDisabled}: BaseInputProps
) {
  return (
    <FormControl disabled={idDisabled}>
        <Stack direction={"column"} spacing={1}>
            <Typography variant="caption">{name}</Typography>
            {children}
        </Stack>
    </FormControl>
  )
}
