import { Stack, Typography } from '@mui/material';
import { AddFileButton } from 'components/buttons/AddFileButton';
import { AppState } from 'index';
import React, { useContext } from 'react'
import useRithmSectionSettings from 'hooks/useRithmSectionSettings';

export function SelectSfInput() {
    const state = useContext(AppState);
    const {handleChangeSelectedSf, handleDeleteSf} = useRithmSectionSettings();
    
  return (
    <Stack direction={"column"} spacing={1}>
        <AddFileButton 
            buttonText='Выбрать sf-файл'
            handleFileChange={handleChangeSelectedSf}
            handleDelete={handleDeleteSf}
        />

        <Typography variant="caption" color={'primary'}><b>File: {state.selectedSf.value.name}</b></Typography>

    </Stack>
  )
}
