import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';

interface DeleteIconButtonProps {
    handleDelete: () => void
}

export function DeleteIconButton(props: DeleteIconButtonProps) {
  return (
    <Button 
        variant='outlined' 
        onClick={props.handleDelete}
    >
        <DeleteOutlineIcon/>
    </Button>
  )
}
