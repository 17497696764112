import { DownloadArchiveButton } from 'components/buttons/DownloadArchiveButton'
import { AppState } from 'index'
import React, { useContext } from 'react'

export function BassResultButton() {
    const state = useContext(AppState)

  return (
    <React.Fragment>
      {
        state.generatedBassPath.value.result_id !== '' ? (
            <DownloadArchiveButton variant='bass' format='midi'/>
        ) : null
      }
    </React.Fragment>
  )
}
