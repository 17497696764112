import { Button } from '@mui/material'
import React, { useContext } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import useAuth from 'hooks/useAuth';
import { AppState } from 'index';
import { fetchArchive, fetchMidiArchive } from 'api/generations.api';

interface DownloadArchiveButtonProps {
    variant: "rhithm" | "synth" | "bass"
    format: "midi" | "wav"
}

export function DownloadArchiveButton(props: DownloadArchiveButtonProps) {
    const state = useContext(AppState);
    const {auth} = useAuth();

    const handleDownload = async () => {
        try {
            if (props.format === 'midi') {
                const response = await fetchArchive(
                    auth, 
                    'standard', 
                    props.variant !== "rhithm" ?
                        props.variant === "synth" ?
                        state.generatedSynthPath.value.result_id
                        : state.generatedBassPath.value.result_id
                    : state.generatedNames.value.result_id,

                    props.variant
                )
                const resUrl = URL.createObjectURL(new Blob([response], {type: 'application/zip'}));
                const link = document.createElement('a');
                link.href = resUrl;
                link.setAttribute('download', `${props.variant}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();  
                URL.revokeObjectURL(resUrl);
            } 
            if (props.format === 'wav') {
                const response = await fetchMidiArchive(
                    auth,
                    state.generatedNames.value.result_id,
                )
                const resUrl = URL.createObjectURL(new Blob([response], {type: 'application/zip'}));
                const link = document.createElement('a');
                link.href = resUrl;

                link.setAttribute('download', `${props.variant}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();  
                URL.revokeObjectURL(resUrl);
            }


        } catch (error) {
            console.error(error);
        }
    }

  return (
    <>
    {
        (props.variant === "rhithm" && state.generatedNames.value.result_id !== '') 
        || (props.variant === "synth" && state.generatedSynthPath.value.result_id !== '') 
        || (props.variant === "bass" && state.generatedBassPath.value.result_id !== '')
        ? (
            <Button
                variant="contained"
                onClick={handleDownload}
            >
                {props.variant}_{props.variant === "bass" ? null : props.format }.zip
                <DownloadIcon/>
            </Button>
        ) : (
            <></>
        )
    }

    </>
  )
}
