import { SelectChangeEvent } from "@mui/material";
import { AppState } from "index";
import { useContext, useState } from "react";

export default function useSynthSettings() {
    const state = useContext(AppState);
    const [selPresetId, setSelPresetId] = useState(0);

    const handleChangeSynthPreset = (e: SelectChangeEvent) => {
        const selPreset = state.synth_presets.value.find(tag => tag.id === Number(e.target.value));

        state.selectedTagSynthSettings.value = {
            ...state.selectedTagSetSettings.value,
            preset: selPreset ? selPreset.id : 1
        }
        setSelPresetId(Number(e.target.value));
    }

    return {
        handleChangeSynthPreset
    }
}