import React, { createContext, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { SelectPage, GeneratedExamplesPage } from 'components/index'
import { Router } from 'utils/routes/routes'
import { createAppState } from 'state/state';
import { Container } from '@mui/material';
// import { useSignals } from '@preact/signals-react/runtime';
import { AppState } from 'index';
import { Login } from 'components/pages/Login';

// export const AppState = createContext(createAppState());

function App() {
  // useSignals();
  const state = useContext(AppState);

  if (!state.isLogin.value) {
    return (
      <Login/>
    )
  } else {
    return (
      <Container>
        <Routes>
            <Route path={Router.Select} element={<SelectPage/>} />
            {/* <Route path={Router.GeneratedExamples} element={<GeneratedExamplesPage />} /> */}
        </Routes>
      </Container>
    );
  }
  
}

export default App;