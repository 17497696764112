import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createAppState } from 'state/state';

export const AppState = createContext(createAppState());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <AppState.Provider value={createAppState()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppState.Provider>
  </React.StrictMode>
);
