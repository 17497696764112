import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'

interface TagNumberInputProps {
    selectedNumber: number,
    handleChangeNumber: (e: React.ChangeEvent<HTMLInputElement>) => void,
    name: string
}

export function TagNumberInput(props: TagNumberInputProps) {
  return (
    <FormControl>
        <OutlinedInput
            value={props.selectedNumber === 0 ? '' : props.selectedNumber}
            onChange={props.handleChangeNumber}
            inputProps={{
                min: 0,
                type: "number"
            }}
            endAdornment={<InputAdornment position="end">{props.name}</InputAdornment>}
        />
    </FormControl>
  )
}
