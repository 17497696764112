import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { generateBass } from 'api/generations.api';
import useAuth from 'hooks/useAuth';
import { AppState } from 'index';
import React, { useContext, useState } from 'react'
import type { GeneratedSynthPath } from 'types';

export default function BassGenerationButton() {
    const state = useContext(AppState);
    const {auth} = useAuth();

    const [isLoaded, setIsLoaded] = useState(false);
    const [errorMessage, setErrorMesage] = React.useState("");
    const [isError, setIsError] = React.useState(false);

    const handleGenerateStart = async () => {
        setIsLoaded(true);
        setIsError(false);
        state.generatedBassPath.value = {
            result_id: ''
        }

        const response = await generateBass(
            state.selectedTagSetSettings.value,
            state.bass_presets.value.find(p => p.id === state.selectedTagSetSettings.value.preset)?.name || state.bass_presets.value[0].name,
            auth
        );
        if (typeof response === 'string') {
            setIsError(true)
            setErrorMesage(response)
        } else {
            state.generatedBassPath.value = {
                result_id: response.result_id
            }
        }
        setIsLoaded(false)
    }

  return (
    <React.Fragment>
        <Button
            disabled={isLoaded}
            variant='contained' 
            title='Сгенерировать' 
            onClick={handleGenerateStart}
        >
            {
                isLoaded ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <CircularProgress/> 
                        <Typography>Loading...</Typography>
                    </Stack>
                ) : 'Сгенерировать'
            }
        </Button>
        {isError ? <Alert severity="error">{errorMessage}</Alert> : null}

    </React.Fragment>
  )
}
